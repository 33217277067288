/* eslint-disable  no-template-curly-in-string */

import { createRouter } from 'tg-resources';
import { SuperAgentResource } from '@tg-resources/superagent';

// https://docs.djangoproject.com/en/3.2/ref/csrf/#ajax
export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === `${name}=`) {
                cookieValue = decodeURIComponent(
                    cookie.substring(name.length + 1),
                );
                break;
            }
        }
    }
    return cookieValue;
}

// https://github.com/thorgate/tg-resources/blob/v3.2.1/README.md#basic-usage
export const apiRouter = createRouter(
    {
        airQualityClassesDistribution:
            'timeseries/get_co2_classes_distribution',
        organizationList: 'organizations/',
        organization: 'organizations/${pk}/',
        floorList: 'floors/',
        floor: 'floors/${pk}/',
        roomList: 'rooms/',
        room: 'rooms/${pk}/',
        zoneList: 'zones/',
        zone: 'zones/${pk}/',
        areaList: 'areas/',
        area: 'areas/${pk}/',
        buildingList: 'buildings/',
        building: 'buildings/${pk}/',
        buildingsEnergyEfficiency: 'timeseries/get_energy_efficiency/',
        buildingsIndoorClimate:
            'timeseries/get_buildings_ordered_by_indoor_climate/',
        buildingThermalComfortDistribution:
            'timeseries/get_thermal_comfort_classes_distribution',
        buildingYearlyIndoorClimate:
            'timeseries/get_building_yearly_indoor_climate/',
        buildingsIndoorAirQualityDistribution:
            'timeseries/get_buildings_indoor_air_quality_classes_distribution/',
        userList: 'users/',
        user: 'users/${pk}/',
        groupList: 'groups/',
        group: 'groups/${pk}/',
        permissionTreeList: 'permission_tree',
        zoneTypeList: 'zone_types',
        zoneType: 'zone_types/${pk}',
        tagTreeList: 'tag_tree',
        tagMeterTreeList: 'tag_tree/get_tag_meter_tree',
        notificationSubscriptionList: 'alerts/notifications/subscription',
        notificationSubscriberList: 'alerts/notifications/subscribers',
        roomQualityClasses: 'timeseries/get_room_quality_class/',
        tagConstraintsList: 'alerts/tags/constraints/',
        tagConstraint: 'alerts/tags/constraints/${pk}/',
        deviceList: 'devices/',
        device: 'devices/${pk}/',
        tagList: 'tags/',
        tag: 'tags/${pk}/',
        controllerList: 'controllers',
        controller: 'controller/${pk}',
        dataStatistics: 'timeseries/get_data_statistics/',
        energyClassStatistics: 'timeseries/get_energy_class_distribution',
        deviceTypeList: 'device_types',
        deviceType: 'device_types/${pk}',
        modalityList: 'modalities/',
        modality: 'modalities/${pk}',
        timeSeries: 'timeseries',
        timeSeriesRawDataLatest: 'timeseries/get_raw_data_latest',
        timeSeriesRawDataReadings: 'timeseries/get_raw_data_readings',
        timeSeriesRawDataTotal: 'timeseries/get_raw_data_total',
        timeSeriesRawDataCount: 'timeseries/get_raw_data_count',
        timeSeriesAverageCo2: 'timeseries/get_average_co2',
        timeSeriesAverageTemp: 'timeseries/get_average_temp',
        timeSeriesAverageAirQualityFeedback:
            'timeseries/get_average_air_quality_feedback',
        timeSeriesAverageThermalComfortFeedback:
            'timeseries/get_average_thermal_comfort_feedback',
        timeSeriesLastYearMonthlyData: 'timeseries/last_year_monthly_data',
        timeSeriesBuildingEnergyPerformance:
            'timeseries/get_building_energy_performance',
        timeSeriesDownload: 'timeseries/download',
        timeSeriesPercentagesDistribution:
            'timeseries/get_consumption_values_percentage_distribution',
        feedbackWeekDistribution: 'timeseries/get_feedback_week_distribution',
        timeSeriesZoneFeedback: 'timeseries/get_zone_feedback',
        timeSeriesLastYearMonthlyGraphData:
            'timeseries/last_year_monthly_graph_data',
        timeSeriesBuildingDistributionData:
            'timeseries/get_building_distribution_data',
        energyBalanceGraphData: 'timeseries/get_energy_balance_data',
        importData: 'dataimport/import_data/?tag_id=${pk}',
        importPicture: 'imageimport/import_image/?floor_id=${pk}',
        logEntryList: 'admin_logs/',
        logEntry: 'admin_logs/${pk}/',
        authEntryList: 'auth_logs/',
        authUniqueUsers: 'auth_logs/get_unique_users',
        authUniqueRoles: 'auth_logs/get_unique_roles_week',
        constraintRelationList: 'constraint_relations/',
        constraintRelation: 'constraint_relations/${pk}/',
        alertList: 'alerts/',
        alert: 'alerts/${pk}/',
        globalRoles: 'global_roles',
        energyCarrierTypeList: 'energy_carrier_type/',
        energyCarrierType: 'energy_carrier_type/${pk}/',
        systemTypeList: 'system_type/',
        systemType: 'system_type/${pk}/',
        hostsDictionaryList: 'hosts_dictionary/',
        hostsDictionary: 'hosts_dictionary/${pk}/',
    },
    {
        apiRoot: '/api/',
        headers: () => ({
            'X-CSRFToken': getCookie('csrftoken'),
            accept: 'application/json',
            'Accept-Language': getCookie('django_language'),
        }),
    },
    SuperAgentResource,
);
